@import '../../src/style/animate/animate.min.css';
.T {
  background-image: url('./img/home/addInstructor.jpg');
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 70%;
  overflow: hidden;
}
.ant-carousel .slick-slide h3 {
  color: #fff;
}
.main-course {
  cursor: pointer;
  margin: 5vh 0vh;
}
.course-title {
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;
}
.course-title div :hover {
  font-weight: bold;
  color: black;
}
.course-content {
  margin: 0vh 0vh 2vh 0vh;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cltC {
  display: flex;
}
.cltC div {
  margin-right: 1rem;
}
.main-info {
  cursor: pointer;
  margin: 5vh 0vh;
}
.main-info .info-content {
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.main-info .info-text {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.main-info .complan {
  display: flex;
  align-items: center;
}
.main-info .complan :nth-child(1) {
  color: grey;
  margin-right: 5px;
}
.main-info .complan :nth-child(2) {
  color: #0057b9;
  margin-right: 5px;
}
.main-info .complan :nth-child(3) {
  color: grey;
  margin-right: 5px;
}
.courseP {
  background-color: white;
}
.courseHTImgC {
  text-align: center;
}
.courseHTImgC {
  background-color: white;
}
.courseItem {
  display: flex;
  width: 50%;
  padding: 3rem 9rem 0rem 9rem;
  box-sizing: border-box;
}
.courseItemC {
  display: flex;
  padding: 10px 10px 10px 30px;
  border-radius: 10px;
  position: relative;
}
.courseItemC:after {
  content: '';
  /*设置margin: 0 auto; 加上相对定位的left和right都为零 可让伪元素在active中水平居中*/
  margin: 20px auto;
  position: absolute;
  bottom: -14rpx;
  left: 0;
  right: 0;
  height: 90%;
  width: 100%;
  border: 1px solid #c8c9ca;
  border-radius: 10px;
}
.course-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.courseText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 0px 20px 20px 20px;
}
.course-item-title {
  font-size: 1.3rem;
  font-weight: 400;
  color: black;
  padding: 10px;
}
.courseMoreT {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.courseMore {
  padding: 1rem 2rem;
  width: 9rem;
  border: 2px solid #73abf9;
  border-radius: 50px;
}
.yuanP {
  background-color: white;
}
.yuanImgP {
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.yuanContent {
  display: flex;
}
.yuanItem {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  border: 1px solid #c8c9ca;
  border-radius: 17px;
  /* 元素 | http://localhost:3000/#/ */
  /* 元素 | http://localhost:3000/#/ */
  /* 元素 | http://localhost:3000/#/ */
}
.yuanItem .yuanImg {
  width: 100%;
  height: 100%;
}
.yuanItem .yuanText-r {
  font-size: 1.3rem;
  font-weight: bold;
}
.yuanItem .yuanText-s {
  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
.yuanItem .yuanText-t {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
.yuanMoreT {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.yuanMore {
  z-index: 999;
  padding: 0.5rem 1rem;
  width: 6rem;
  border: 2px solid #73abf9;
  border-radius: 50px;
  color: #73abf9;
}
.resourseContent {
  display: flex;
}
.resourseItem {
  display: flex;
  flex-direction: column;
  width: -moz-available;
  position: relative;
  /* 元素 | http://localhost:3000/#/ */
  /* 元素 | http://localhost:3000/#/ */
  /* 元素 | http://localhost:3000/#/ */
}
.resourseItem::after {
  content: '';
  margin: 40px auto;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #c8c9ca;
  border-radius: 10px;
}
.resourseItem .yuanImg {
  width: 100%;
  height: 100%;
}
.resourseItem .yuanText-r {
  font-size: 1.3rem;
  font-weight: bold;
}
.resourseItem .yuanText-s {
  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
.resourseItem .yuanText-t {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}
.activeTakeTitle {
  color: #6591f5;
  margin: 0rem 2rem 2rem 2rem;
  padding-bottom: 5px;
  border-bottom: 2px solid #6591f5;
  text-decoration-color: #6591f5;
}
.TakeTitle {
  color: black;
  margin: 0rem 2rem 2rem 2rem;
}
.produceP {
  display: flex;
  justify-content: space-evenly;
}
.produceItem {
  width: 23%;
  border: 1px solid #c8c9ca;
  border-radius: 10px;
}
.produceItem-footer {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 1rem 0rem;
  color: #939599;
}
.produceItem-introduce {
  color: #939599;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.tagP {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 5rem 0rem 1rem 0rem;
  flex-direction: column;
  align-items: center;
}
.tagItem {
  display: flex;
  align-items: center;
}
.tagItem div {
  font-weight: bold;
  font-size: 2rem;
  color: #515151;
  font-family: 'Courier New', Courier, monospace;
  margin-top: 2rem;
}
.take-title {
  display: flex;
  justify-content: center;
}
.planContent {
  display: flex;
  background-color: white;
  height: 20rem;
}
.plan-title {
  position: absolute;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 90%;
  font-size: 1.5rem;
  margin-top: 10px;
}
.plan-content {
  bottom: 30%;
  position: absolute;
  display: flex;
  align-items: end;
  margin: 0rem 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.infoContent {
  display: flex;
  justify-content: center;
  background-color: white;
}
.infomationP {
  margin: 2rem;
  border: 1px solid #c8c9ca;
  border-radius: 10px;
  padding: 1.5rem;
  width: 50%;
}
.itemP {
  display: flex;
  justify-content: center;
}
.infoIntro {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.info-foot {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #C4C6CC;
}
